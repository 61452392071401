import { Box, Button, Select, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';


export const StyledDashboardButton = styled(Button)`
&&{
    border: 1px solid rgba(0,0,0,0.22);
    color: #888;
    &:hover{
        border: 1px solid rgba(0,0,0,0.7);
    }
    &:active{
        color: #B9E123;
    }
    @media all and (max-width: 599px){
        min-width: 54px;
    }
}
`;

const dotAnimation = keyframes`
  0% { opacity: 0; }
  25% { opacity: 1; }
  50% { opacity: 0; }
  75% { opacity: 0; }
  100% { opacity: 0; }
`;

export const CustomLoadingIndicator = styled(Box)`
&&{
  display: flex;
  align-items: center;
  justify-content: center;

  & .text {
    font-size: 16px;
    margin-right: 3px;
    font-weight: 500;
    color: #555;
  }

  & .dot {
    width: 2px;
    height: 2px;
    background-color: #555;
    border-radius: 50%;
    margin-top: 4px;
    margin-left: 4px;
    animation: ${dotAnimation} 1.5s infinite ease-in-out;

    &:nth-of-type(1) {
      animation-delay: 0s;
    }
    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
}
`;

